<template>
  <main class="preferencias">


    <div class="outages p-4">
      <div class="subtitle">
      <p>Avisos</p>
    </div>

      <b-row>
        <b-col class="toggle-container">
          <toggle-button
              :sync="true"
              :height="32"
              :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
              v-model="preferences.notifications_for_accounts_payable_near_10_days"
              @input="updatePreferences(preferences.id)"
              :disabled="!canEdit"
            />
            <p class="toggle-description">Permitir avisos do sistema para contas a pagar com vencimento próximo (10 dias)</p>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col class="toggle-container">
          <toggle-button
              :sync="true"
              :height="32"
              :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
              v-model="preferences.notifications_for_accounts_receivable_near_10_days"
              @input="updatePreferences(preferences.id)"
              :disabled="!canEdit"
            />
            <p class="toggle-description">Permitir avisos do sistema para contas a receber com vencimento próximo (10 dias)</p>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col class="toggle-container">
          <toggle-button
              :sync="true"
              :height="32"
              :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
              v-model="preferences.notifications_for_accounts_payable_expired"
              @input="updatePreferences(preferences.id)"
              :disabled="!canEdit"
            />
            <p class="toggle-description">Permitir avisos do sistema para contas a receber vencidas</p>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col class="toggle-container">
          <toggle-button
              :sync="true"
              :height="32"
              :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
              v-model="preferences.notifications_for_accounts_receivable_expired"
              @input="updatePreferences(preferences.id)"
              :disabled="!canEdit"
            />
            <p class="toggle-description">Permitir avisos do sistema para contas a pagar vencidas</p>
        </b-col>
      </b-row>
    </div>

    <div class="bills-print-config p-4">
      <div class="title">Impressão de contas a receber</div>

      <b-col>
        <b-form-group>
          <b-form-radio-group size="lg" v-model="selectedGroupByBillToReceive" :options="optionsGroupBy" @change="() => handleChangeSelectGroupBy('bill-to-pay')" stacked plain>
          </b-form-radio-group>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <b-form-checkbox-group size="lg" v-model="selectedViewingBillToReceive" :options="optionsViewingBillToReceive" @change="() => handleChangeSelectViewing('bill-to-pay')" stacked>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

    </div>
    <div class="bills-print-config p-4">
      <div class="title">Impressão de contas a pagar</div>

      <b-col>
        <b-form-group>
          <b-form-radio-group size="lg" v-model="selectedGroupByBillToPay" :options="optionsGroupBy" @change="() => handleChangeSelectGroupBy('bill-to-pay')" stacked plain>
          </b-form-radio-group>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <b-form-checkbox-group size="lg" v-model="selectedViewingBillToPay" :options="optionsViewingBillToPay" @change="() => handleChangeSelectViewing('bill-to-pay')" stacked>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

    </div>
</main>
</template>

<script>
import { userHasPermission } from '@/utils/localStorageManager'
import * as billPrintingPreferences from '@/utils/billPrintingPreferencesHelper'

export default {
  name: 'preferences-table',

  components: {
  },

  data() {
    const clinic = JSON.parse(localStorage.getItem('clinic'));

    return {
      canEdit: userHasPermission('FiConPref2'),
      clinic_id: clinic.id,
      preferences: {
        id: null,
        clinic_id: null,
        notifications_for_accounts_payable_near_10_days: false,
        notifications_for_accounts_receivable_near_10_days: false,
        notifications_for_accounts_payable_expired: false,
        notifications_for_accounts_receivable_expired: false,
      },
      checked: false, // SWITCH BUTTON DA TABELA
      optionsGroupBy: [
        { html: 'Agrupar contas por <b>período</b>', value: billPrintingPreferences.GROUP_BY_PERIOD },
        {
          html: 'Agrupar contas por dia de <b>criação da conta</b>',
          value: billPrintingPreferences.GROUP_BY_CREATED_AT
        }
      ],
      optionsViewingBillToReceive: [
        {
          html: 'Agrupar contas por <strong>método de recebimento</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_PAYMENT_METHOD_GROUP_BY
        },
        {
          html: 'Mostrar na tabela coluna de <strong>forma de recebimento</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_PAYMENT_METHOD
        },
        {
          html: 'Mostrar valor <strong>total de descontos</strong>',
          value:  billPrintingPreferences.VIEWING_OPTIONS_TOTAL_DISCOUNT
        },
        {
          html: 'Mostrar valor <strong>total líquido</strong>',
          value:  billPrintingPreferences.VIEWING_OPTIONS_TOTAL_LIQUID
        },
        {
          html: 'Mostrar na tabela coluna <strong>de conta bancária</strong>',
          value:    billPrintingPreferences.VIEWING_OPTIONS_BANK_ACCOUNT
        },
        {
          html: 'Mostrar na tabela coluna <strong>de taxa</strong>',
          value:  billPrintingPreferences.VIEWING_OPTIONS_RATE
        },
        {
          html: 'Mostrar na tabela coluna <strong>de parcela(s)</strong>',
          value:  billPrintingPreferences.VIEWING_OPTIONS_INSTALLMENT
        },
        {
          html: 'Mostrar detalhamento (quantidade e total líquido) por <strong>tipo de item</strong>',
          value:  billPrintingPreferences.VIEWING_OPTIONS_DETAIL
        }
      ],
      optionsViewingBillToPay: [
        {
          html: 'Agrupar contas por <strong>método de pagamento</strong>',
          value:  billPrintingPreferences.VIEWING_OPTIONS_PAYMENT_METHOD_GROUP_BY
        },
        {
          html: 'Mostrar na tabela coluna de <strong>forma de pagamento</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_PAYMENT_METHOD
        },
        {
          html: 'Mostrar valor <strong>total líquido</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_TOTAL_LIQUID
        },
        {
          html: 'Mostrar na tabela coluna <strong>de conta bancária</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_BANK_ACCOUNT
        },
        {
          html: 'Mostrar na tabela coluna <strong>de taxa</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_RATE
        },
        {
          html: 'Mostrar na tabela coluna <strong>de parcela(s)</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_INSTALLMENT
        },
        {
          html: 'Mostrar detalhamento de quantidade e total líquido',
          value: billPrintingPreferences.VIEWING_OPTIONS_DETAIL
        }
      ],
      selectedGroupByBillToReceive: 'period',
      selectedViewingBillToReceive: [],
      selectedGroupByBillToPay: 'period',
      selectedViewingBillToPay: []
    }

  },

  mounted() {
    this.getPreferences();
    this.getBillPrintingPreferences();
  },

  methods: {
    // LISTA AS PREFERÊNCIAS
    getPreferences() {

      this.api
      .getPreferences(this.clinic_id)
      .then(res => {
        res.data.length && (this.preferences = res.data[0])
      })
      .catch(err => {
        console.log('Err', err)
      })
    },

    updatePreferences(id) {
      const isLoading = this.$loading.show()

      if(!id) {
        this.api
          .createPreference(
            {
              clinic_id: this.clinic_id,
              notifications_for_accounts_payable_near_10_days: this.preferences.notifications_for_accounts_payable_near_10_days,
              notifications_for_accounts_receivable_near_10_days: this.preferences.notifications_for_accounts_receivable_near_10_days,
              notifications_for_accounts_payable_expired: this.preferences.notifications_for_accounts_payable_expired,
              notifications_for_accounts_receivable_expired: this.preferences.notifications_for_accounts_receivable_expired
            }
          )
          .then(res => {
            this.getPreferences();
            this.$toast.success('Preferência atualizada com sucesso!');
          })
          .catch(err => this.$toast.error(err.message))
          .finally(() => {
            isLoading.hide();
          })
      } else {
        this.api
          .updateStatusPreference({ 
              id: this.preferences.id, 
              notifications_for_accounts_payable_near_10_days: this.preferences.notifications_for_accounts_payable_near_10_days,
              notifications_for_accounts_receivable_near_10_days: this.preferences.notifications_for_accounts_receivable_near_10_days, 
              notifications_for_accounts_payable_expired: this.preferences.notifications_for_accounts_payable_expired,
              notifications_for_accounts_receivable_expired: this.preferences.notifications_for_accounts_receivable_expired 
          })
          .then(res => {
            this.getPreferences()
            this.$toast.success('Preferência atualizada com sucesso!');
          })
          .catch(err => {
            console.log('err', err)
          })
          .finally(() => {
            isLoading.hide()
          })
      }
    },

    async getBillPrintingPreferences() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getBillPrintingPreferences(this.clinic_id)

        const billToReceive = data.find(item => item.type === 'bill_to_receive')
        const billToPay = data.find(item => item.type === 'bill_to_pay')

        if (billToReceive) {
          this.selectedGroupByBillToReceive = billToReceive.group_by
          this.selectedViewingBillToReceive = billToReceive.viewing_options
        }

        if (billToPay) {
          this.selectedGroupByBillToPay = billToPay.group_by
          this.selectedViewingBillToPay = billToPay.viewing_options
        }
        
        
      } catch (error) {
        console.log('error', error)
        this.$toast.error('Erro ao buscar configurações de impressão, por favor tente novamente mais tarde ou entre em contato com o suporte.')
      } finally {
        isLoading.hide()
      }
    },

    async handleChangeSelectGroupBy() {
      this.updateBillPrintingPreferences()
    },
    async handleChangeSelectViewing() {
      this.updateBillPrintingPreferences()
    },

    async updateBillPrintingPreferences() {
      try {
        const data = [
          {
            clinic_id: this.clinic_id,
            type: 'bill_to_receive',
            group_by: this.selectedGroupByBillToReceive,
            viewing_options: this.selectedViewingBillToReceive
          },
          {
            clinic_id: this.clinic_id,
            type: 'bill_to_pay',
            group_by: this.selectedGroupByBillToPay,
            viewing_options: this.selectedViewingBillToPay
          }
        ]

        await this.api.updateBillPrintingPreferences(data)
      } catch (error) {
      console.log('error', error)
      this.$toast.error('Erro ao atualizar configurações de impressão, por favor tente novamente mais tarde ou entre em contato com o suporte.')
      }
    }

  }
}
</script>

<style lang="scss">
.preferencias {
  padding: 40px 10px 90px 10px;
  width: 100%;

  .doctor-selected-title {
    font-family: 'Red Hat Display';
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 30px;
    color: #525c7a;
  }

  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
    margin-bottom: 27px;
  }

  .subtitle {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding-bottom: 24px;

    p {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: var(--type-active);
    }

    .icon {
      height: 24px;
      width: 24px;
      stroke: var(--neutral-500);
    }
  }

  .outages {
    border: 1px solid #D9DFF2;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: left;

    .toggle-container {
      display: flex;
      flex-direction: row;
      gap: 24px;
      align-items: center;

      .toggle-description {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }
    }

    .order-body {
      border-radius: 8px;

      p {
        padding-left: 20px;
        padding-top: 20px;
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #0c1d59;
      }

      .order-options {
        max-width: 396px;
        padding-left: 20px;
      }
    }

    .add-btn {
      text-align: center;
      margin: 20px 0px;

      button {
        width: 220px;
        height: 52px;
        border: 2px solid #305BF2;
        box-sizing: border-box;
        border-radius: 8px;

        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #305BF2;

        &.saveBtn {
          color: #305BF2;
          border: 2px solid #305BF2;
        }

        &.cancel {
          color: #FFEBCC;
          border: 2px solid#ffdccc;
        }
      }
    }

    .head {
      height: 70px;
      padding: 0px 20px;
      justify-content: space-between;
      align-items: center;
      color: #525C7A;

      .title {
        margin: 0px;
        font-family: Red Hat Display;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
      }

      .date {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;

        svg {
          width: 35px;
          height: 25px;
          stroke: #305BF2;
          cursor: pointer;
        }
      }
    }

    .data-head {
      padding: 10px 20px;
      display: grid;
      grid-template-columns: 15% 30% 30% 15% 10%;
      align-items: flex-start;
      background: #F4F5FB;
      color: #525C7A;

      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .data-body {
      padding: 0px 20px;

      .items {
        padding: 0px;
        margin: 10px 0px;

        .item {
          list-style: none;
          align-items: center;
          display: grid;
          grid-template-columns: 15% 30% 30% 15% 10%;
          align-items: center;
          border-bottom: 1px solid #D9DFF2;
          padding: 20px 0px;

          .dates {
            align-items: center;
          }

          .times-tag {
            padding: 8px 16px;
            border-radius: 50px;
            background-color: #d9dff2;
            color: #000;
            width: fit-content;

            &.entrada {
              background: #DBFFF0;
            }

            &.saida {
              background: #FEDAD7;
            }
          }

          .receive {
            justify-content: space-between;

            .options {
              cursor: pointer;
              width: 24px;
              height: 28px;
              position: relative;
              display: inline-block;

              .more-icon {
                width: 24px;
                height: 24px;
                stroke: var(--neutral-500);
                transition: all 0.5s;
                cursor: pointer;
              }

              .menu {
                width: 160px;
                position: absolute;
                top: 28px;
                right: 0;
                background: var(--neutral-000);
                border: 1px solid var(--neutral-200);
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                border-radius: 8px;
                padding: 24px;
                opacity: 0;
                transition: all 0.5s;
                flex-direction: column;
                align-items: start;
                display: none;

                .mtb-12 {
                  margin: 12px 0;
                }

                .btn {
                  font-weight: 600;
                  margin-bottom: 16px;

                  &.remove {
                    color: var(--states-error);
                  }
                }
              }

              &:hover {
                .more-icon {
                  transform: rotate(90deg);
                }

                .menu {
                  opacity: 1;
                  display: flex;
                  z-index: 10;
                }
              }
            }
          }
        }

        .create {
          list-style: none;
          align-items: center;
          display: grid;
          grid-template-columns: 15% 30% 30% 15% 10%;
          align-items: center;
          border-bottom: 1px solid #D9DFF2;
          padding: 20px 0px;

          .date-inputs {
            input {
              text-align: center;
              border: 1px solid #C6CEEB;
              box-sizing: border-box;
              border-radius: 8px;
              width: 90%;
              height: 55px;
            }
          }

          .reason {
            .multiselect {
              min-width: 180px !important;

              .multiselect__single {
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                color: var(--type-active);
              }
            }
          }

          .multiselect {
            border: 1px solid #C6CEEB;
            box-sizing: border-box;
            border-radius: 8px;
            width: 60%;

            .multiselect__tags {
              padding-right: 0px;
            }

            .multiselect__single {
              font-family: Nunito Sans;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 150%;
              color: var(--type-active);
            }
          }

          .receive {
            justify-content: space-between;

            .status {
              height: 20px;
              margin-top: 4px;
            }

            .multiselect {
              min-width: 130px !important;

              .multiselect__single {
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                color: var(--type-active);
              }
            }

            .close {
              cursor: pointer;
              display: flex;
              align-items: center;

              svg {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
  .bills-print-config {
    border: 1px solid #D9DFF2;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: left;
    margin-top: 20px;

    .title {
      font-family: Nunito Sans;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #525C7A;
    }

    label {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-size: 18px;
      font-weight: 400;
      color: var(--type-active);
    }
  }
}
</style>
