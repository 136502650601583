export const GROUP_BY_PERIOD = 'period';
export const GROUP_BY_CREATED_AT = 'created_at';
export const VIEWING_OPTIONS_PAYMENT_METHOD_GROUP_BY = 'payment_method_group_by';
export const VIEWING_OPTIONS_PAYMENT_METHOD = 'payment_method';
export const VIEWING_OPTIONS_TOTAL_DISCOUNT = 'total_discount';
export const VIEWING_OPTIONS_TOTAL_LIQUID = 'total_liquid';
export const VIEWING_OPTIONS_BANK_ACCOUNT = 'bank_account';
export const VIEWING_OPTIONS_RATE = 'rate';
export const VIEWING_OPTIONS_INSTALLMENT = 'installment';
export const VIEWING_OPTIONS_DETAIL = 'detail';

export const VIEWING_OPTIONS = [
    VIEWING_OPTIONS_PAYMENT_METHOD_GROUP_BY,
    VIEWING_OPTIONS_PAYMENT_METHOD,
    VIEWING_OPTIONS_TOTAL_DISCOUNT,
    VIEWING_OPTIONS_TOTAL_LIQUID,
    VIEWING_OPTIONS_BANK_ACCOUNT,
    VIEWING_OPTIONS_RATE,
    VIEWING_OPTIONS_INSTALLMENT,
    VIEWING_OPTIONS_DETAIL
];

export const GROUP_BY_OPTIONS = [
    GROUP_BY_PERIOD,
    GROUP_BY_CREATED_AT
];

export const BILL_TO_RECEIVE = 'bill_to_receive';
export const BILL_TO_PAY = 'bill_to_pay';

export const BILL_TYPE_OPTIONS = [
    BILL_TO_RECEIVE,
    BILL_TO_PAY
];
